import {Link} from "react-router-dom";


function Footer() {
    return (
        <footer class="nav-footer"> 
            <div class="container-footer-cta"> 
                <div class="started-cta-info">
                    <h1>  Get started today!</h1>
                    <h3> Experience real estate investing returns <br></br> without the headache. </h3>
                </div>
                <div class="container-started-cta-btn">
                    <div class="started-cta-btn"> 
                        <Link to="contactus" id="footer-cta-btn">Invest With Us</Link>
                    </div>
                </div>
            </div>
            <div class="started-cta-directory">
                <div class="container-footer-links">
                <ul class="footer-links"> 
                        <li class="footer-link top-footer-link">Resources</li>
                        <li class="footer-link">
                            <Link to="howitworks">How It Works</Link>
                        </li>
                        <li class="footer-link">
                            <Link to="pastprojects">Past Projects</Link>
                        </li>
                        <li class="footer-link">
                            <Link to="calculator">Return Calculator</Link>
                        </li>
                    </ul>
                    <ul class="footer-links"> 
                        <li class="footer-link top-footer-link">Company</li>
                        <li class="footer-link">
                            <Link to="aboutus">About Us</Link>
                        </li>
                        <li class="footer-link">
                            <Link to="contactus">Contact Us</Link>
                        </li>
                        <li class="footer-link">
                            <Link to="investorletter">Investor Letter</Link>
                        </li>
                    </ul>
                    <ul class="footer-links"> 
                        <li class="footer-link top-footer-link">Other</li>
                        <li class="footer-link">
                            <a href="https://www.linkedin.com/company/willbemobilelogistics/" target="_blank" rel="noreferrer">Social Media</a>
                        </li>
                        <li class="footer-link">
                            <Link to="press">Press</Link>
                        </li>
                    </ul>
                </div>
                <div class="rights"> © 2022 Mutuall <br></br> All Rights Reserved </div>
            </div>
        </footer>
    )
}

export default Footer;