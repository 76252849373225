import MetavineSimpleLogo from "../images/metavine.png";
import {Link} from "react-router-dom";

function Navbar() {
    return (
         <div id="page-nav" className="nav"> 
             <div className="container-desktop-nav"> 
                 <header className="nav-header">
                     <div className="nav-whole">  
                         <ul className="nav-main"> 
                             <li id="logo" className="nav-block"> 
                                 <Link to="/" className="logo">
                                     <img src={MetavineSimpleLogo} alt="Metavine Properties" width={121}/>
                                 </Link>
                             </li>
                             <li className="nav-block"> 
                                 <p>Resources</p>
                             </li>
                             <li className="nav-block"> 
                                 <Link to="/aboutus">About Us</Link>
                             </li>
                             <li className="nav-block" > 
                                 <Link to="/investorletter">Investor Letter</Link>   
                             </li>
                         </ul>
                         <ul className="nav-cta"> 
                             <li className="nav-block"> 
                                 <Link to="/contactus" id="start-btn">Invest With Us</Link>
                             </li> 
                         </ul>
                     </div>
                 </header>
             </div>
         </div>
     )
 }
 
 export default Navbar;