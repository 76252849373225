import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomeSections from "./components/HomeSections";
import AboutUs from "./components/AboutUs";
import Calc from "./components/Calc";
import ContactUs from "./components/ContactUs";
import HowItWorks from "./components/HowItWorks";
import InvestorLetter from "./components/InvestorLetter";
import PastProjects from "./components/PastProjects";
import Press from "./components/Press";
import UnderConstruction from "./components/UnderConstruction";
import ErrorPage from "./components/ErrorPage";


function App() {

    return (
        <Router>
            <div className="app-main">
                <Navbar />
                <Routes>
                    <Route exact path="/" element={<HomeSections />} />
                    <Route exact path="/aboutus" element={<AboutUs />} />
                    <Route exact path="/calculator" element={<Calc />} />
                    <Route exact path="/contactus" element={<ContactUs />} />
                    <Route exact path="/howitworks" element={<HowItWorks />} />
                    <Route exact path="/investorletter" element={<InvestorLetter />} />
                    <Route exact path="/pastprojects" element={<PastProjects />} />
                    <Route exact path="/press" element={<Press />} />
                    <Route exact path="/underconstruction" element={<UnderConstruction />} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    )
}


export default App;
