import React from "react";

function Calc() {
    return (
        <div className="container-wrapper">
            <h1 class="main-container">tehehehe</h1>
            <br>take a break</br>
            <h1 class="main-container">tehehehe</h1>
            <h1 class="main-container">tehehehe</h1>
            <h1 class="main-container">tehehehe</h1>
            <h1 class="main-container">tehehehe</h1>
            <h1>tehehehe</h1>
            <h1>tehehehe</h1>
            <h1>tehehehe</h1>
            <h1>tehehehe</h1>
        </div>
    )
}

export default Calc;