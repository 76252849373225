import HomeSection1 from "./HomeSection1";
import HomeSection2 from "./HomeSection2";

function HomeSections() {
    return (
        <div className="container-wrapper">
            <HomeSection1 />
            <HomeSection2 />
        </div>
    )
}

export default HomeSections;