import React from "react";

function aboutUs() {
    return (
        <div>

        </div>   
        
    )
}

export default aboutUs;