import React from "react";

function InvestorLetter() {
    return (
        <div>
            <h1>hehehe</h1>
        </div>   
        
    )
}

export default InvestorLetter;